$base: rgb(148, 125, 211);
$base-light: rgb(245, 244, 248);
$font: "Mulish", sans-serif;

@mixin mq($breakpoint) {
  @if $breakpoint==xs {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint==sm {
    @media only screen and (min-width: 600px) {
      @content;
    }
  }
  @if $breakpoint==md {
    @media only screen and (min-width: 900px) {
      @content;
    }
  }
  @if $breakpoint==lg {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
  @if $breakpoint==xl {
    @media only screen and (min-width: 1400px) {
      @content;
    }
  }
}

html,
body {
  min-height: 100vh;
  background-color: $base-light;
}

body {
  margin: 0;
  font-family: $font !important;
  font-weight: 600 !important;
  text-align: center;
  overflow-y: visible;
}

button,
input,
li,
textarea,
.MuiTypography-root,
.MuiTableCell-root,
.MuiDataGrid-root,
.MuiInputLabel-root,
.MuiFormHelperText-root,
.MuiPaper-root,
.MuiAlert-message,
.MuiSelect-select,
.MuiFormLabel-root,
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  font-family: $font !important;
  font-weight: 600 !important;
}

button {
  font-weight: 700 !important;
}

main {
  margin: 0 auto 10em;
  width: 90%;

  @include mq(md) {
    width: 80%;
  }

  @include mq(lg) {
    width: 60%
  }
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}

.bg-base {
  background-color: $base !important;
}

.hover:hover {
  cursor: pointer;
}

.text-link:hover {
  color: $base;
}

.white {
  color: white;
  background-color: transparent;
}


.person-list .MuiDataGrid-row:hover, .MuiAutocomplete-option:hover, .MuiMenuItem-root:hover {
  cursor: pointer;
  background-color: rgba(25, 118, 210, 0.08) !important;
}

.form-grid > .MuiGrid-item {
  padding: 10px !important;
}


.MuiDialogContent-root .MuiTableContainer-root .MuiTableRow-root .table-row-header {
  font-weight: 800 !important;
  width: 50%;
 
  @include mq(sm) {
    text-align: right !important;
  }
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 800 !important;
}

.person-dialog .MuiDialog-container,
.appt-dialog .MuiDialog-container  {
  height: auto !important;
  position: relative !important;
  top: 30px !important;

  @include mq(sm) {
    top: 50px !important;
  }
}

.MuiPaper-root {
  overflow-x: hidden !important;
}

.MuiDialogContent-root {
 overflow-y: hidden !important;
}

.person-dialog .MuiBox-root, 
.appt-dialog .MuiBox-root {
  padding: 0 !important;
}


.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.dashboard-btn h2 {
  margin-top: 0;
  font-size: 1.8em;
  letter-spacing: 0.05em;
}
