html,
body {
  min-height: 100vh;
  background-color: #f5f4f8;
}

body {
  margin: 0;
  font-family: "Mulish", sans-serif !important;
  font-weight: 600 !important;
  text-align: center;
  overflow-y: visible;
}

button,
input,
li,
textarea,
.MuiTypography-root,
.MuiTableCell-root,
.MuiDataGrid-root,
.MuiInputLabel-root,
.MuiFormHelperText-root,
.MuiPaper-root,
.MuiAlert-message,
.MuiSelect-select,
.MuiFormLabel-root,
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  font-family: "Mulish", sans-serif !important;
  font-weight: 600 !important;
}

button {
  font-weight: 700 !important;
}

main {
  margin: 0 auto 10em;
  width: 90%;
}

@media only screen and (min-width: 900px) {
  main {
    width: 80%;
  }
}

@media only screen and (min-width: 1200px) {
  main {
    width: 60%;
  }
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}

.bg-base {
  background-color: #947dd3 !important;
}

.hover:hover {
  cursor: pointer;
}

.text-link:hover {
  color: #947dd3;
}

.white {
  color: white;
  background-color: transparent;
}

.person-list .MuiDataGrid-row:hover, .MuiAutocomplete-option:hover, .MuiMenuItem-root:hover {
  cursor: pointer;
  background-color: rgba(25, 118, 210, 0.08) !important;
}

.form-grid > .MuiGrid-item {
  padding: 10px !important;
}

.MuiDialogContent-root .MuiTableContainer-root .MuiTableRow-root .table-row-header {
  font-weight: 800 !important;
  width: 50%;
}

@media only screen and (min-width: 600px) {
  .MuiDialogContent-root .MuiTableContainer-root .MuiTableRow-root .table-row-header {
    text-align: right !important;
  }
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 800 !important;
}

.person-dialog .MuiDialog-container,
.appt-dialog .MuiDialog-container {
  height: auto !important;
  position: relative !important;
  top: 30px !important;
}

@media only screen and (min-width: 600px) {
  .person-dialog .MuiDialog-container,
  .appt-dialog .MuiDialog-container {
    top: 50px !important;
  }
}

.MuiPaper-root {
  overflow-x: hidden !important;
}

.MuiDialogContent-root {
  overflow-y: hidden !important;
}

.person-dialog .MuiBox-root,
.appt-dialog .MuiBox-root {
  padding: 0 !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.dashboard-btn h2 {
  margin-top: 0;
  font-size: 1.8em;
  letter-spacing: 0.05em;
}
